



































































































































import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class OrderDetail extends Vue {
  public order = {}; // 订单信息
  public id = ""; // 订单id
  public user_id = "";
  // 删除订单
  orderDelete(){
    let _this = this;
    this.$api.request({
      url: "order/order-info/order-invalid",
      data: {
        order_id: this.id,
        user_id: this.user_id
      },
      success(res) {
        _this.$toast('订单已成功取消')
        setTimeout(item=>{
          _this.$api.href(`/order?user_id=${_this.user_id}&active=1`);
        },1000)
      }
    });
  }
  // 获取订单详情
  getOrderDetail() {
    let _this = this;
    this.$api.request({
      url: "order/order-info/order-details",
      data: {
        order_id: this.id,
        user_id: this.user_id
      },
      success(res) {
        let order = res.data;
        order.order_mation.map(item => {
          return item;
        });
        _this.order = order;
      }
    });
  }

  init() {
    this.id = String(this.$route.query.id || "");
    this.user_id = String(this.$route.query.user_id || "");
    this.getOrderDetail();
  }
}
